import { Component, Show } from 'solid-js';
import { MenuIcon, CloseIcon } from './icons';

interface MobileMenuProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const MobileMenu: Component<MobileMenuProps> = (props) => (
  <div class="min-[601px]:hidden flex items-center px-[5px] w-[calc(25%-10px)]">
    <div
      class={`mobile-menu__toggle-icon flex items-center cursor-pointer py-[10px] pr-[5px] ${props.isOpen ? 'is-active' : ''}`}
    >
      <div class="h-6">
        <span class="inline-flex w-6 h-6" onClick={props.onToggle}>
          <Show when={!props.isOpen} fallback={<CloseIcon />}>
            <MenuIcon />
          </Show>
        </span>
      </div>
    </div>
    <div
      class={`mobile-menu max-h-full absolute w-full z-40 overflow-y-auto ${props.isOpen ? 'left-0' : '-left-[110%]'} top-[calc(100%+10px)] min-h-fit border-black border-[3px] rounded-[10px] bg-white h-[calc(-44px+100vh)]`}
    >
      <ul>
        <li class="p-5 border-b-[3px] border-black">
          <a
            href="https://shop.wearshare.com/pages/eras-giveaway"
            target="_self"
            class="py-2 px-6 flex text-[20px] leading-6 tracking-[0.03em] font-bold font-roboto"
            aria-label="Link to ERAS GIVEAWAY"
          >
            ERAS GIVEAWAY
          </a>
        </li>
        <li class="p-5 border-b-[3px] border-black">
          <a
            href="https://shop.wearshare.com/collections/premade-bandz"
            target="_self"
            class="py-2 px-6 flex text-[20px] leading-6 tracking-[0.03em] font-bold font-roboto"
            aria-label="Link to SHOP PREMADE BANDZ."
          >
            SHOP PREMADE BANDZ
          </a>
        </li>
        <li class="p-5 border-black border-b-0">
          <a
            href="https://shop.wearshare.com/products/wearshare-gift-card"
            target="_self"
            class="py-2 px-6 flex text-[20px] leading-6 tracking-[0.03em] font-bold font-roboto"
            aria-label="Link to SHOP PREMADE BANDZ."
          >
            GIFT CARDS
          </a>
        </li>
      </ul>
    </div>
  </div>
);
