import { type Component, createSignal } from 'solid-js';
import { MobileMenu } from './MobileMenu.jsx';
import { UserIcon, CartIcon } from './icons.jsx';

export const Header: Component = () => {
  const [isMenuOpen, setIsMenuOpen] = createSignal(false);

  return (
    <>
      <div class={`mobile-layer ${isMenuOpen() ? 'open' : ''}`} />
      <header>
        <div class="w-full pt-[1%] fixed">
          <div
            id="header"
            class="w-[calc(100%-20px)] mx-[10px] z-[4] min-[600px]:z-20 rounded-[10px] border-black border-[3px] bg-[#fbff53]"
          >
            <div class="relative min-[601px]:w-[95%] min-[601px]:mx-auto min-[601px]:flex min-[601px]:flex-wrap">
              <div class="min-[601px]:mx-2.5 min-[601px]:w-[calc(100%-20px)] flex items-center max-[601px]:px-[20px] max-[480px]:px-[10px]">
                <MobileMenu
                  isOpen={isMenuOpen()}
                  onToggle={() => setIsMenuOpen(!isMenuOpen())}
                />

                {/* Logo Section */}
                <div class="flex items-center py-4 px-3 max-[601px]:px-[5px] max-[601px]:py-[5px] max-[601px]:grow w-[calc(50%-10px)]">
                  <a
                    class="w-full max-[250px]:hidden min-[601px]:w-[120px]"
                    href="https://shop.wearshare.com"
                    title="Wearshare"
                  >
                    <div
                      class="overflow-hidden mx-auto max-h-full"
                      style="max-width: 220px;"
                    >
                      <img
                        class="max-w-full h-full w-full block mx-auto object-cover object-[50%50%]"
                        src="./assets/images/WRSR_Secondary_HRZ_BLK.svg"
                        alt="Wearshare"
                      />
                    </div>
                  </a>
                  <a
                    class="w-full min-[250px]:hidden"
                    href="https://shop.wearshare.com"
                    title="Wearshare"
                  >
                    <div
                      class="overflow-hidden mx-auto max-h-full"
                      style="max-width: 220px;"
                    >
                      <img
                        class="max-w-full h-full w-full block mx-auto object-cover object-[50%50%]"
                        src="./assets/images/WRSR_BrandMark_BLK.png"
                        alt="Wearshare"
                      />
                    </div>
                  </a>
                </div>

                {/* Desktop Navigation */}
                <div class="max-[600px]:hidden flex flex-wrap justify-end flex-auto">
                  <nav
                    class="flex justify-end gap-8"
                    role="navigation"
                    aria-label="main navigation"
                  >
                    <div class="flex items-center p-0 relative grow-0 shrink-0 leading-6">
                      <a
                        href="https://shop.wearshare.com/pages/eras-giveaway"
                        target="_self"
                        class="uppercase font-bold font-roboto tracking-[1px] leading-[26px]"
                      >
                        ERAS GIVEAWAY
                      </a>
                    </div>
                    <div class="flex items-center p-0 relative grow-0 shrink-0 leading-6 max-[650px]:hidden">
                      <a
                        href="https://shop.wearshare.com/collections/premade-bandz"
                        target="_self"
                        class="uppercase font-bold font-roboto tracking-[1px] leading-[26px]"
                      >
                        SHOP PREMADE BANDZ
                      </a>
                    </div>
                    <div class="flex items-center p-0 relative grow-0 shrink-0 leading-6 max-[750px]:hidden">
                      <a
                        href="https://shop.wearshare.com/products/wearshare-gift-card"
                        target="_self"
                        class="uppercase font-bold font-roboto tracking-[1px] leading-[26px]"
                      >
                        GIFT CARDS
                      </a>
                    </div>
                  </nav>
                </div>

                {/* User Actions */}
                <div class="flex justify-end items-center min-[601px]:gap-4 min-[601px]:ml-4 max-[601px]:px-[5px] max-[601px]:w-[calc(25%-10px)]">
                  <a
                    href="https://shop.wearshare.com/customer_authentication/redirect?locale=en&region_country=US"
                    class="max-[600px]:hidden flex flex-col justify-center items-center h-full w-full px-1.5"
                    aria-label="Log in to your Wearshare account."
                  >
                    <span
                      class="inline-flex justify-center items-center"
                      style="height: 16.8px;width: 16.8px;"
                    >
                      <UserIcon />
                    </span>
                  </a>
                  <div class="flex justify-end items-center gap-4">
                    <a
                      href="https://shop.wearshare.com/cart"
                      aria-label="Cart page."
                    >
                      <span class="flex justify-center relative w-max mx-auto my-0">
                        <span
                          class="inline-flex justify-center items-center"
                          style="height: 16.8px;width: 16.8px;"
                        >
                          <CartIcon />
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
